<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../../assets/build/vfs_fonts";

import apiConfig from "../../../../config/api";
import whApi from "@/api/warehouse/";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import moment from "moment";
import numbro from "numbro";

export default {
  props: {
    dataExportPdf: Object,
  },
  data: () => ({
    jsonExport: [],

    newDataTable: [],

    user: "",
    numberToTh: "",
  }),
  async created() {
    console.log(this.dataExportPdf, 123);
    await this.groupDateExport();
  },

  methods: {
    groupDateExport() {
      this.setDataToTable();
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");

        return oldDate;
      } else {
        return "-";
      }
    },

    checkTransactionType(type, item) {
      if (type) {
        if (type.substring(0, 3) === "POS") {
          this.styleTitleName = "color: #7337EE; font-weight: bold;";
          return "ขาย POS";
        }

        if (type.substring(0, 3) === "800") {
          if (item.sales_type === "0") {
            return "ขายส่ง";
          }

          if (item.sales_type === "1") {
            return "ขายปลีก";
          }
        }

        if (type.substring(0, 3) === "ADD") {
          return "ปรับสินค้าเข้า (+)";
        }

        if (type.substring(0, 3) === "DEL") {
          return "ปรับสินค้าออก (-)";
        }

        if (type.substring(0, 3) === "TO0") {
          return "ย้ายสินค้าออก";
        }

        if (type.substring(0, 3) === "TI0") {
          return "ย้านสินค้าเข้า";
        }

        if (type.substring(0, 3) === "RO2") {
          return "ซื้อสินค้า";
        }

        if (type.substring(0, 3) === "NEW") {
          return "เพิ่มสินค้าใหม่";
        }
      }
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      const html = [
        {
          text: "รายงานสินค้าขายดี",
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
        },

        // TABLE
        {
          table: {
            // width: "100%",
            widths: ["*", "*", "*", "*", "*"],
            body: this.newDataTable,
          },
        },
      ];

      const pdfData = {
        content: html,
        pageOrientation: "landscape",
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setDataToTable() {
      this.dataExportPdf.forEach((element, index) => {
        if (index === 0) {
          this.newDataTable.push(
            [
              {
                fontSize: 9,
                text: "วันเวลา",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "เลขที่เอกสาร",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "ชื่อรายการ",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "ราคา",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "ผู้ทำรายการ",
                alignment: "center",
              },
            ],
            [
              {
                text: this.formatDate(element.createdAt),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: element.sales_no
                  ? element.sales_no
                  : element.point_of_sale_no
                  ? element.point_of_sale_no
                  : element.transfer_no
                  ? element.transfer_no
                  : element.cut_off_no
                  ? element.cut_off_no
                  : element.receive_no
                  ? element.receive_no
                  : element.product_new_code
                  ? element.product_new_code
                  : element.purchase_no,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: this.checkTransactionType(
                  element.sales_no
                    ? element.sales_no
                    : element.point_of_sale_no
                    ? element.point_of_sale_no
                    : element.transfer_no
                    ? element.transfer_no
                    : element.cut_off_no
                    ? element.cut_off_no
                    : element.receive_no
                    ? element.receive_no
                    : element.product_new_code
                    ? element.product_new_code
                    : element.purchase_no,
                  element
                ),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: numbro(
                  element.final_price
                    ? this.formatPrice(element.final_price)
                    : this.formatPrice(
                        element.product_first_bring_qty *
                          element.product_cost_price
                      )
                ).format({
                  thousandSeparated: true,
                  mantissa: 2,
                }),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: `${element.user ? element.user.firstName : ""} ${
                  element.user ? element.user.lastName : ""
                }`,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
            ]
          );
        } else {
          this.newDataTable.push([
            {
              text: this.formatDate(element.createdAt),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: element.sales_no
                ? element.sales_no
                : element.point_of_sale_no
                ? element.point_of_sale_no
                : element.transfer_no
                ? element.transfer_no
                : element.cut_off_no
                ? element.cut_off_no
                : element.receive_no
                ? element.receive_no
                : element.product_new_code
                ? element.product_new_code
                : element.purchase_no,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: this.checkTransactionType(
                element.sales_no
                  ? element.sales_no
                  : element.point_of_sale_no
                  ? element.point_of_sale_no
                  : element.transfer_no
                  ? element.transfer_no
                  : element.cut_off_no
                  ? element.cut_off_no
                  : element.receive_no
                  ? element.receive_no
                  : element.product_new_code
                  ? element.product_new_code
                  : element.purchase_no,
                element
              ),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: numbro(
                element.final_price
                  ? this.formatPrice(element.final_price)
                  : this.formatPrice(
                      element.product_first_bring_qty *
                        element.product_cost_price
                    )
              ).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: `${element.user ? element.user.firstName : ""} ${
                element.user ? element.user.lastName : ""
              }`,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
          ]);
        }
      });

      this.exports();
    },
  },
};
</script>
