<template>
  <div v-if="loading && stateLoading">
    <Loader />
  </div>
  <div class="card" v-if="!loading && !stateLoading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        รายงานสรุปรายการ Transaction
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>

        <DialogExportFile
          :dialogExportFile="dialogExportFile"
          @closeDialogExportFile="closeDialogExportFile"
          @selectedTypeExportFile="selectedTypeExportFile"
        />
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-range-picker
          v-model:value="searchInput"
          format="DD/MM/YYYY"
          style="border-radius: 6px; height: 38px"
          inputReadOnly
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table align-middle table-hover table-row-bordered table-row-dashed gy-5"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">วันเวลา</th>
              <th class="text-center">เลขที่เอกสาร</th>
              <th class="text-center">ชื่อรายการ</th>
              <th class="text-center">ราคา</th>
              <th class="text-center">ผู้ทำรายการ</th>
              <!-- <th class="text-center">หมายเหตุ</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataShow" :key="index">
              <td class="text-center">{{ formatDate(item.createdAt) }}</td>
              <td class="text-center">
                {{
                  item.sales_no
                    ? item.sales_no
                    : item.point_of_sale_no
                    ? item.point_of_sale_no
                    : item.transfer_no
                    ? item.transfer_no
                    : item.cut_off_no
                    ? item.cut_off_no
                    : item.receive_no
                    ? item.receive_no
                    : item.product_new_code
                    ? item.product_new_code
                    : item.purchase_no
                }}
              </td>
              <td
                :style="
                  setStyleTitleName(
                    item.sales_no
                      ? item.sales_no
                      : item.point_of_sale_no
                      ? item.point_of_sale_no
                      : item.transfer_no
                      ? item.transfer_no
                      : item.cut_off_no
                      ? item.cut_off_no
                      : item.receive_no
                      ? item.receive_no
                      : item.product_new_code
                      ? item.product_new_code
                      : item.purchase_no,
                    item
                  )
                "
                class="text-center"
              >
                {{
                  checkTransactionType(
                    item.sales_no
                      ? item.sales_no
                      : item.point_of_sale_no
                      ? item.point_of_sale_no
                      : item.transfer_no
                      ? item.transfer_no
                      : item.cut_off_no
                      ? item.cut_off_no
                      : item.receive_no
                      ? item.receive_no
                      : item.product_new_code
                      ? item.product_new_code
                      : item.purchase_no,
                    item
                  )
                }}
              </td>
              <td class="text-end">
                {{
                  item.final_price
                    ? formatPrice(item.final_price)
                    : formatPrice(
                        item.product_first_bring_qty * item.product_cost_price
                      )
                }}
              </td>
              <td class="text-center">
                {{ item.user ? item.user.firstName : "" }}
                {{ item.user ? item.user.lastName : "" }}
              </td>
              <!-- <td
                :style="
                  !item.purchase_no
                    ? ''
                    : item.status === '1'
                    ? ''
                    : 'text-decoration: underline; font-weight: 600'
                "
              >
                {{
                  !item.purchase_no
                    ? ""
                    : item.status === "1"
                    ? "ยังไม่รับเข้าคลัง"
                    : "รับเข้าคลังแล้ว"
                }}
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import reportApi from "@/api/report/";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { mapState } from "vuex";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "BELUCA | รายงานสรุปรายการ Transaction";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataShow: [],
    companyItems: [],

    showing1: null,

    tableItems: [],
    itemApprove: {},

    searchInput: [],
    allData: [],

    dateFormat: "YYYY/MM/DD",
    value4: "",

    styleTitleName: "color: #00e396; font-weight: bold;",

    //export
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    loadingExport1: false,
  }),

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  async created() {
    await this.setDateToday();
    await this.search();
  },

  methods: {
    setDateToday() {
      this.searchInput[0] = dayjs(new Date());
      this.searchInput[1] = dayjs(new Date());
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");

        return oldDate;
      } else {
        return "-";
      }
    },

    checkTransactionType(type, item) {
      if (type) {
        if (type.substring(0, 3) === "POS") {
          this.styleTitleName = "color: #7337EE; font-weight: bold;";
          return "ขาย POS";
        }

        if (type.substring(0, 3) === "800") {
          if (item.sales_type === "0") {
            return "ขายส่ง";
          }

          if (item.sales_type === "1") {
            return "ขายปลีก";
          }
        }

        if (type.substring(0, 3) === "ADD") {
          return "ปรับสินค้าเข้า (+)";
        }

        if (type.substring(0, 3) === "DEL") {
          return "ปรับสินค้าออก (-)";
        }

        if (type.substring(0, 3) === "TO0") {
          return "ย้ายสินค้าออก";
        }

        if (type.substring(0, 3) === "TI0") {
          return "ย้านสินค้าเข้า";
        }

        if (type.substring(0, 3) === "RO2") {
          return "ซื้อสินค้า";
        }

        if (type.substring(0, 3) === "NEW") {
          return "เพิ่มสินค้าใหม่";
        }
      }
    },

    setStyleTitleName(type, item) {
      if (type) {
        if (type.substring(0, 3) === "POS") {
          return "color: #0BB7AF; font-weight: bold;";
        }

        if (type.substring(0, 3) === "800") {
          if (item.sales_type === "0") {
            return "color: YellowGreen; font-weight: bold;";
          }

          if (item.sales_type === "1") {
            return "color: green; font-weight: bold;";
          }
        }

        if (type.substring(0, 3) === "ADD") {
          return "color: #F64E60; font-weight: bold;";
        }

        if (type.substring(0, 3) === "DEL") {
          return "color: #F64E60; font-weight: bold;";
        }

        if (type.substring(0, 3) === "TO0") {
          return "color: #EE9D01; font-weight: bold;";
        }

        if (type.substring(0, 3) === "TI0") {
          return "color: #EE9D01; font-weight: bold;";
        }

        if (type.substring(0, 3) === "RO2") {
          return "color: #7337EE; font-weight: bold;";
        }

        if (type.substring(0, 3) === "NEW") {
          return "color: #3699ff; font-weight: bold;";
        }
      }
    },

    async search() {
      if (this.searchInput.length > 0) {
        this.loading = true;
        this.$store.commit(Mutations.SET_LOADING, true);
        const companyId = localStorage.getItem("companyId");
        const companyBranchId = localStorage.getItem("companyBranchId");

        const newDateStart = moment(this.searchInput[0].$d).format(
          "YYYY-MM-DD"
        );
        const newDateEnd = moment(this.searchInput[1].$d)
          .add(1, "days")
          .format("YYYY-MM-DD");

        const responseSearch = await reportApi.sale.searchTransByDate(
          {
            dateStart: newDateStart,
            dateEnd: newDateEnd,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
        );

        if (responseSearch.response_status === "SUCCESS") {
          this.descDataTable(responseSearch.data);
        } else {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          });
        }

        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
        this.loading = false;
      }
    },

    descDataTable(item) {
      this.dataShow = item.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      this.tableItems = this.dataShow;

      this.dataExportExcel = this.dataShow;
      this.dataExportPdf = this.dataShow;
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },

    //export file excel, pdf
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
